import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Grid from '@material-ui/core/Grid';

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectCard from "../components/projectCard"
import styled from "styled-components"
import portfolioPic from "../../content/assets/portfolio.png"
import ecommPic from "../../content/assets/ecomm.png"
import pathTracerPic from "../../content/assets/path-tracer.png"
import tipPic from "../../content/assets/tour-into-the-picture.png"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ProjectPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`yifang li`]} />

      <article className="post-content page-template">
        <div className="post-content-body">
            <Container>
            <Grid container spacing={6}>
            <Grid item xs={12} sm={6} md={3}>
        <ProjectCard
          img={portfolioPic}
          title='Personal Website'
          description="Portfolio website written in React, built with Gatsby"
          tags={["React", "CSS", "HTML"]} 
        />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
        <ProjectCard
          img={ecommPic}
          title='E-Commerce UI'
          description="An end to end E-Commerce demo website with shopping cart and recommendations"
          tags={["React", "FastAPI", "Python", "CSS"]} 
          link={"https://persostack-ecomm-ui-dot-gamma-15191-56.wl.r.appspot.com/"}
        />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
        <ProjectCard
          img={pathTracerPic}
          title='Monte Carlo Path Tracer'
          description="Path tracer with lighting, sampling, lights and various material shaders"
          tags={["C++"]} 
        />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
        <ProjectCard
          img={tipPic}
          title='Tour Into the Picture'
          description="Portfolio website written in React"
          tags={["Three.js", "openCV", "JavaScript"]} 
          link={"https://github.com/yli262/tour-into-the-picture"}
        />
        </Grid>
        </Grid>
        </Container>
        </div>
      </article>
    </Layout>
  )
}

const Cards = styled.div`
    display: flex;
    align-items: flex-start;
`

const Container = styled.div`
    position: relative;
    width: 75vw;
    min-width: 100%;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    transform: translateX(calc(50vw - 50%));
`

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ProjectPage location={props.location} data={data} {...props} />
    )}
  />
)
