import React from "react"
import styled from "styled-components"

export default class ProjectCard extends React.Component {
    render() {
      return(
          <Container>
            <ProjectLink href={this.props.link || undefined} target="_blank">
            <img src={this.props.img} />
            <Detail>
              <strong>{this.props.title}</strong>
              <p>{this.props.description}</p>
              <div>
              {this.props.tags.map((tag, index) => 
              <Tag key={index}>{tag}</Tag>
                )}
            </div>
            </Detail>
            </ProjectLink>
          </Container>
      )
    }
  }

const Container = styled.div`
    background: #fff;
    width: 100%;
    height: 100%;
    border-radius: 0.2em;
    overflow: hidden;
    cursor: pointer;
    color: #444;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.23);
    text-decoration: none;
    img {
        width: 100%;
        object-fit: cover;
    }
    span + span {
        margin-left: 0.75rem;
    }
    p {
       font-size: 1.5rem; 
       margin: 0 0 0.4em 0;
    }

`

const Tag = styled.span`
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 4px;
    display: inline-flex;
    font-size: 1.3rem;
    height: 2em;
    justify-content: center;
    line-height: 1;
    padding-left: 0.75em;
    padding-right: 0.75em;
    white-space: nowrap;
`

// box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
// transition: all ease 200ms;

const Detail = styled.div`
    padding: 1em 1.2em;
`

// height: 100%;
// display: flex;
// flex-direction: column;
// justify-content: flex-end;

const ProjectLink = styled.a`
 text-decoration: none;
 color: #4a4a4a;
 :hover {
    text-decoration: none;
 }
`